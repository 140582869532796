import { Recommendation } from "./Recommendation";

import { client, withAuthTokens } from "@api/client";
import { supabase } from "@hooks/use-client";
import { Carousel, type Embla } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { ActionIcon, ActionIconGroup, Group, Stack, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

const useRecommendations = () => {
  return useQuery({
    queryKey: ["recommendations"],
    queryFn: async ({ signal }) => {
      const authHeader = await withAuthTokens(supabase);
      const { data, error } = await client.GET("/api/recommendations", {
        params: { ...authHeader, query: { count: 9 } },
        signal,
      });

      if (error) {
        throw error;
      }

      return data.recommendations;
    },
  });
};

export const Recommendations = ({
  createReport,
}: {
  createReport: (starter: string) => Promise<void>;
}) => {
  const [embla, setEmbla] = useState<Embla | null>(null);
  const { data: currentRecommendations } = useRecommendations();

  return (
    <Stack gap={"sm"} w={"100%"}>
      <Group justify="space-between">
        <Text c={"dimmed"} size="sm">
          Some recommendations based on your data
        </Text>
        <ActionIconGroup>
          <ActionIcon onClick={() => embla?.scrollPrev()} variant="subtle">
            <BiLeftArrowAlt size={20} />
          </ActionIcon>
          <ActionIcon onClick={() => embla?.scrollNext()} variant="subtle">
            <BiRightArrowAlt size={20} />
          </ActionIcon>
        </ActionIconGroup>
      </Group>
      <Carousel
        getEmblaApi={setEmbla}
        withControls={false}
        loop
        slideSize="33.333333%"
        slideGap="md"
        align="start"
        slidesToScroll={1}
      >
        {Array.isArray(currentRecommendations)
          ? currentRecommendations.map((rec) => (
              <Carousel.Slide key={rec.starter} display={"flex"}>
                <Recommendation
                  createReport={createReport}
                  starter={rec.starter}
                  explanation={rec.explanation}
                  type={rec.type}
                />
              </Carousel.Slide>
            ))
          : null}
      </Carousel>
    </Stack>
  );
};
