import { useQuery } from "@tanstack/react-query";

import { client, withAuthTokens } from "@api/client";
import { supabase } from "@hooks/use-client";
import { GetReportColumnOptions } from "@mm/shared/endpoints/companion/reports.routes";
import { useParams } from "react-router-dom";
import { groupBySourceAndTable } from "../utils";

export const useJoinableColumns = () => {
  const { reportId } = useParams<{ reportId: string }>();

  return useQuery({
    queryKey: ["allJoinableColumns", { reportId }],
    queryFn: async () => {
      const authHeader = await withAuthTokens(supabase);
      const { data, error } = await client.GET(GetReportColumnOptions.path, {
        params: {
          ...authHeader,
          path: {
            reportId: String(reportId),
          },
        },
      });

      if (error) {
        throw new Error(`Failed to fetch joinable columns, ${error}`);
      }

      return GetReportColumnOptions.responses[200].content[
        "application/json"
      ].schema.parse(data).joinableColumns;
    },
    select: groupBySourceAndTable,
  });
};
