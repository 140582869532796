import { createRoute, z } from "@hono/zod-openapi";
import { SupabaseHeaderSchema } from "./auth";
import type { Report, ReportColumnCandidate } from "@mm/shared/schemas/reports";

const ReportIdParam = z
  .object({ reportId: z.string().pipe(z.coerce.number()) })
  .openapi("ReportId", { type: "integer" });

export const GetReport = createRoute({
  method: "get",
  path: "/api/reports/{reportId}",
  operationId: "getReport",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.unknown().transform((x) => x as Report),
        },
      },
      description: "Return a report",
    },
  },
});

export const GetReportColumnOptions = createRoute({
  method: "get",
  path: "/api/reports/{reportId}/columns/options",
  operationId: "getReportColumnOptions",
  request: {
    headers: SupabaseHeaderSchema,
    params: ReportIdParam,
  },
  responses: {
    200: {
      content: {
        "application/json": {
          schema: z.object({
            joinableColumns: z
              .unknown()
              .transform((x) => x as ReportColumnCandidate[]),
          }),
        },
      },
      description: "The list of column that can be added to the report",
    },
    500: {
      description: "Something wrong happened",
      content: {
        "application/json": {
          schema: z.object({
            error: z.string(),
          }),
        },
      },
    },
  },
});
