import createClient, { type Middleware } from "openapi-fetch";
import { API_URL } from "../../config";
import type { paths } from "./v1";
import type { SupabaseClient } from "@supabase/supabase-js";

const apiClient = createClient<paths>({ baseUrl: API_URL });

const throwOnError: Middleware = {
  async onResponse({ response }) {
    if (response.status >= 400) {
      const body = response.headers.get("content-type")?.includes("json")
        ? ((await response.clone().json()) as unknown)
        : await response.clone().text();
      if (typeof body === "string") {
        throw new Error(body);
      } else {
        throw new Error("Something wrong happened");
      }
    }
    return undefined;
  },
};

apiClient.use(throwOnError);

export const client = apiClient;

/* to merge with withAuthTokens from admin console fronteed */
export const withAuthTokens = async (supabase: SupabaseClient) => {
  const session = await supabase.auth.getSession();

  if (!session.data.session) {
    throw new Error("No session");
  }

  const { access_token } = session.data.session;

  return {
    header: {
      authorization: `Bearer ${access_token}`,
    },
  };
};
